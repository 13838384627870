import Web3 from 'web3';
import React from 'react';
import { ethers } from "ethers";

import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import coinbaseWalletModule from '@web3-onboard/coinbase';




import AbiFactoryPolygonV4 from "./contracts/MyFactoryV6.json";



import { useState, useEffect } from "react";
import './BuilderMain1.css';


import update from './update.png';

import matic from './matic.svg';

import wallet from './desconectar.png';




import dividends from './dividends.png';
import logotrans from './patronlogotrans.png';



import {
   Button,
   Input,
} from 'reactstrap';




function BuilderMainV6() {



  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  const thelang = urlParams.get("lang");




  const blockchainpolygon = 137;




  const chain1 = 'https://eth.public-rpc.com';
  const chain10 = 'https://op.getblock.io/62c55a95-23bf-4cf2-8cdd-c551ad9ffc00/mainnet/';
  const chain137 = 'https://polygon-rpc.com/';
  const chain42161 = 'https://arbitrum.public-rpc.com';
  const chain56 = 'https://bscrpc.com';
  const chain250 = 'https://rpc.ftm.tools';
  const chain43114 = 'https://avalanche.public-rpc.com';
  const chain42220 = 'https://rpc.ankr.com/celo';



  



  const injected = injectedModule();
  const coinbaseWalletSdk = coinbaseWalletModule();
  
  const onboard = Onboard({
    wallets: [injected, coinbaseWalletSdk],
    chains: [
      {
        id: '1',
        token: 'ETH',
        label: 'Ethereum Mainnet',
        rpcUrl: chain1
      },
      {
        id: '10',
        token: 'OPT',
        label: 'Optimism',
        rpcUrl: chain10
      },
      {
        id: '137',
        token: 'MATIC',
        label: 'Polygon',
        rpcUrl: chain137
      },
      {
        id: '42161',
        token: 'ARB',
        label: 'Arbitrum',
        rpcUrl: chain42161
      },
      {
        id: '250',
        token: 'FTM',
        label: 'Fantom',
        rpcUrl: chain250
      },
      {
        id: '42220',
        token: 'CELO',
        label: 'CELO',
        rpcUrl: chain42220
      },
      {
        id: '43114',
        token: 'AVAX',
        label: 'Avalanche',
        rpcUrl: chain43114
      },
      {
        id: '56',
        token: 'BNB',
        label: 'BNB Smart Chain',
        rpcUrl: chain56
      }
      ],
  
    appMetadata: {
      name: "cryptopatron",
      
      description: "Lend and Give",
     
     
    }
  
  })
  


  const web4 = new Web3('https://polygon-rpc.com/');



















  const contractAddressPolygonV4 = "0xd7aB3Bb9a44b5e33af9197112e997B2fb6A8AEdf";
  
  const contractFactoryPolygon1V4 = new web4.eth.Contract(AbiFactoryPolygonV4, contractAddressPolygonV4);


const MARKETDAI = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";
const MARKETUSDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
const MARKETUSDC = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";


const [acc, setAcc] = useState(0);
const [chainid, setchainid] = useState(0);
const [poolsdatapolygonV4, setPoolsDataPolygonV4] = useState([]);
const [polygontab, setpolygontab] = useState(1);
const [poolstab, setpoolstab] = useState(0);
const [mensaje, setmensaje] = useState("");
const [mensajeok, setmensajeok] = useState("");
const [lang, setlang] = useState(0);
const [web3, setweb3] = useState();
const [isconnected, setisconnected] = useState(0);
const [theprovider, settheprovider] = useState();



    useEffect(() => {
      


      if (theprovider?.on) {
        const handleAccountsChanged = (accounts) => {
          setAcc(accounts[0]);
          setPoolsDataPolygonV4([]);
        };
    
        const handleChainChanged = (chainId) => {
          setchainid(chainId);
        };
    
    
        theprovider.on("accountsChanged", handleAccountsChanged);
        theprovider.on("chainChanged", handleChainChanged);
    
    
        return () => {
          if (theprovider.removeListener) {
            theprovider.removeListener("accountsChanged", handleAccountsChanged);
            theprovider.removeListener("chainChanged", handleChainChanged);
          }
        };
      }
      
    
     
  },[theprovider]);



      

  useEffect(() => {
   
    
   
    if (thelang == 1) {
      setlang(1);
    } else {

      if (thelang == 2) {
        setlang(2);
      } else {

        setlang(1);
      } 
    }  
  
   
},[]);








  const disconnect = async () => {


    const [primaryWallet] = await onboard.state.get().wallets;
    if (primaryWallet) await onboard.disconnectWallet({ label: primaryWallet.label });
  




    setmensaje("");
    setmensajeok("");

    setPoolsDataPolygonV4([]);
    
    setAcc(0);
    setisconnected(0);
    settheprovider("");

    setpolygontab(1);
    setpoolstab(0);



  }




  const connectMetamask = async () => {




    setmensaje("");
    setmensajeok("");


    const wallets = await onboard.connectWallet();

  
    if (wallets[0]) {
      // create an ethers provider with the last connected wallet provider
      
      const { accounts, chains, provider } = wallets[0];
  
      setweb3(new Web3(provider));
  
      setisconnected(1);  
      setAcc(accounts[0].address);
      
      settheprovider(provider);
      setchainid(chains[0].id);
  
  
  
    }    








//////////////////////////////////////////////////
/*
    setmensaje("");
    setmensajeok("");


    if(window.ethereum) {
    
    setweb3(new Web3(window.ethereum));

    settheprovider(window.ethereum);
        
    var network = await window.ethereum.request({ method: 'eth_chainId' });
    setchainid(network); 

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

    const account = accounts[0];
    
    if(accounts.length > 0) {
    setAcc(account);
    setisconnected(1);  
    }

    
    } else {
    
      if(lang == 1) {
        setmensaje("Install Metamask");
      }
      if(lang == 2) {
        setmensaje("Instale Metamask");
      }
    }  
*/


  }






  

  const Refresh = async () => {

    if(polygontab == 1) {
      HandleSetPolygonTab();
    }  
  
   if(poolstab == 1) {
    HandleSetPoolsTab();
   }
  
  }  
  







const HandleSetEnglish = () => {
  setlang(1);
  setPoolsDataPolygonV4([]);

}

const HandleSetSpanish = () => {
  setlang(2);
  setPoolsDataPolygonV4([]);

}


const HandleSetPolygonTab = async () => {
  
    
  setmensaje("");
  setmensajeok("");

  

  if (polygontab == 0) {
    setpolygontab(1);
    setpoolstab(0);
  }



 }




 function HandleVerification() {

  var verified = 0;
  var theaddress = acc;

  if (web4.utils.isAddress(theaddress) == true) {
    verified = 1;
  }  
  
  return verified;

}




function HandleVerificationSend() {

  var verified = 0;  

  var connected = isconnected;
  var network = chainid;
  var account = acc;  
  
    if (connected == 0) {


      if (lang == 1) {  
        setmensaje("Connect Wallet");
      }  
    
      if (lang == 2) {  
        setmensaje("Conectar Billetera");
      }  


    } else {
      
      if (web4.utils.isAddress(account) == false) {
      
        if (lang == 1) {  
          setmensaje("Connect Wallet");
        }  
      
        if (lang == 2) {  
          setmensaje("Conectar Billetera");
        }  
        
      
      } else {


        if (network != blockchainpolygon) {


          if (lang == 1) {
          setmensaje("Switch to Polygon network");
          }  

          if (lang == 2) {
            setmensaje("Cambiar a Red Polygon");
          }


        } else {

        verified = 1;
        
        
    }
      }
    }   

    return verified;

  }      



const HandleSetPoolsTab = async () => {


setmensaje("");
setmensajeok("");

setPoolsDataPolygonV4([]);

  if (poolstab == 0) {
    setpoolstab(1);
    setpolygontab(0);
  }





  var verificado = HandleVerification();



if (verificado == 1) {
      
  var account = acc;


var poolsresultspolygonV4 = [];
poolsresultspolygonV4 = await contractFactoryPolygon1V4.methods.getOwnerPools(account).call();


if (poolsresultspolygonV4.length > 0) {
  const bbb = [];
      var b = poolsresultspolygonV4.length - 1;
         
      var tokenname;
      var theurl;
      

      

      while (b > -1) {


        if (MARKETDAI.toLowerCase().localeCompare((poolsresultspolygonV4[b][1]).toLowerCase()) == 0) {
          tokenname = "DAI";	
      }
      
      
      if (MARKETUSDT.toLowerCase().localeCompare((poolsresultspolygonV4[b][1]).toLowerCase()) == 0) {
          tokenname = "USDT";	
      }
      
      
      if (MARKETUSDC.toLowerCase().localeCompare((poolsresultspolygonV4[b][1]).toLowerCase()) == 0) {
          tokenname = "USDC";	
      }
      
       
        
      theurl = "https://app.cryptopatron.me?pool=";
        


      
  bbb.push(<tr>
  

  <td><div class="text text-start"><small>POLYGON</small></div></td>

  <td><div class="text text-start"><small>{tokenname}</small></div></td>
      

  <td><div class="text text-end"><small><a href={theurl + poolsresultspolygonV4[b][0]} target="_blank">{poolsresultspolygonV4[b][0].substr(0,6)}...{poolsresultspolygonV4[b][0].substr(-4)}</a></small></div></td>  


  

  


  </tr>);
  
  b = b - 1;
      
  }
  
  setPoolsDataPolygonV4(bbb);
  
  }
}
}







    


/////////////////////////////////////////////////////////////////////////////////

const CreatePoolPolygonDAI = async () => {

setmensaje("");
setmensajeok("");

var verificado = HandleVerificationSend();

      if (verificado == 1) {
                
        var account = acc;  

  
        
    if (lang == 1) {
    setmensajeok("Confirm the creation of the pool");
    }

    if (lang == 2) {
      setmensajeok("Confirme la creación del pool");
      }
  

      var toBN = Web3.utils.toBN;


      var gasAmount = await (new web3.eth.Contract(AbiFactoryPolygonV4, contractAddressPolygonV4)).methods.newCryptoPatronPool(MARKETDAI).estimateGas({ 
        from: account});
  
        gasAmount = Math.trunc(gasAmount * 1.25);

        //var theGasPrice = await web3.eth.getGasPrice();


        const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
        const gasStationObj = JSON.parse(await gasStationResponse.text());
        
        var block_number = gasStationObj.blockNumber;
      
        var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
        var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
        
        base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
        max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
          
        var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
        var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
        max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));





try{
    const result = await (new web3.eth.Contract(AbiFactoryPolygonV4, contractAddressPolygonV4)).methods.newCryptoPatronPool(MARKETDAI).send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })




      .once('transactionHash', function(transactionHash) {
      
        setmensaje("");
        setmensajeok("");  



        if(lang == 1) {
          setmensajeok("Transaction sent. Pending...");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion enviada. Pendiente...");
          }
             
      })
  
      
      .once('confirmation', function(confirmationNumber, receipt){

        setmensaje("");
        setmensajeok("");  



        if (receipt.status) {

            if(lang == 1) {
            setmensajeok("Transaction confirmed");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion confirmada");
            }
        
        
      } else {

        
        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }



      }
      

      })

      .once('error', function(error, receipt){


        setmensaje("");
        setmensajeok("");  


        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }
  
      });  
    }catch(e){

    }



}

}


const CreatePoolPolygonUSDT = async () => {

  setmensaje("");
  setmensajeok("");
  
  var verificado = HandleVerificationSend();
  
        if (verificado == 1) {
                  
          var account = acc;  
  
          
      if (lang == 1) {
      setmensajeok("Confirm the creation of the pool");
      }
  
      if (lang == 2) {
        setmensajeok("Confirme la creación del pool");
        }
    

        var toBN = Web3.utils.toBN;
        
        var gasAmount = await (new web3.eth.Contract(AbiFactoryPolygonV4, contractAddressPolygonV4)).methods.newCryptoPatronPool(MARKETUSDT).estimateGas({ 
          from: account});
  

          gasAmount = Math.trunc(gasAmount * 1.25);

          //var theGasPrice = await web3.eth.getGasPrice();


          const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
          const gasStationObj = JSON.parse(await gasStationResponse.text());
          
          var block_number = gasStationObj.blockNumber;
        
          var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
          var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
          
          base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
          max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
            
          var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
          var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
          max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
  
  




try{  
      const result = await (new web3.eth.Contract(AbiFactoryPolygonV4, contractAddressPolygonV4)).methods.newCryptoPatronPool(MARKETUSDT).send({ 
        from: account,
        gas: gasAmount,
        maxFeePerGas: max_fee_per_gas,
        maxPriorityFeePerGas: max_priority_fee })
        
  
        .once('transactionHash', function(transactionHash) {
      
          setmensaje("");
          setmensajeok("");  
  
  
  
          if(lang == 1) {
            setmensajeok("Transaction sent. Pending...");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion enviada. Pendiente...");
            }
               
        })
    
        
        .once('confirmation', function(confirmationNumber, receipt){
  
          setmensaje("");
          setmensajeok("");  
  
  
  
          if (receipt.status) {
  
              if(lang == 1) {
              setmensajeok("Transaction confirmed");
              }  
          
              if(lang == 2) {
                setmensajeok("Transaccion confirmada");
              }
          
          
        } else {
  
          
          if(lang == 1) {
            setmensaje("Transaction Failed");
            }
    
            if(lang == 2) {
            setmensaje("Transaccion Fallida");
            }
  
  
  
        }
        
  
        })
  
        .once('error', function(error, receipt){
  
  
          setmensaje("");
          setmensajeok("");  
  
  
          if(lang == 1) {
            setmensaje("Transaction Failed");
            }
    
            if(lang == 2) {
            setmensaje("Transaccion Fallida");
            }
    
        });  
      }catch(e){
        
      }  
  
        
  
  }
  
  }
  
  
  const CreatePoolPolygonUSDC = async () => {
  
  setmensaje("");
  setmensajeok("");
  
  var verificado = HandleVerificationSend();
  
        if (verificado == 1) {
                  
          var account = acc;  
  
  
  
          
      if (lang == 1) {
      setmensajeok("Confirm the creation of the pool");
      }
  
      if (lang == 2) {
        setmensajeok("Confirme la creación del pool");
        }
    

        var toBN = Web3.utils.toBN;

        var gasAmount = await (new web3.eth.Contract(AbiFactoryPolygonV4, contractAddressPolygonV4)).methods.newCryptoPatronPool(MARKETUSDC).estimateGas({ 
          from: account});
  


          gasAmount = Math.trunc(gasAmount * 1.25);

          //var theGasPrice = await web3.eth.getGasPrice();


          const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
          const gasStationObj = JSON.parse(await gasStationResponse.text());
          
          var block_number = gasStationObj.blockNumber;
        
          var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
          var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
          
          base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
          max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
            
          var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
          var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
          max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
  
  




  try{
      const result = await (new web3.eth.Contract(AbiFactoryPolygonV4, contractAddressPolygonV4)).methods.newCryptoPatronPool(MARKETUSDC).send({ 
        from: account,
        gas: gasAmount,
        maxFeePerGas: max_fee_per_gas,
        maxPriorityFeePerGas: max_priority_fee })
        
   
  
        .once('transactionHash', function(transactionHash) {
      
          setmensaje("");
          setmensajeok("");  
  
  
  
          if(lang == 1) {
            setmensajeok("Transaction sent. Pending...");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion enviada. Pendiente...");
            }
               
        })
    
        
        .once('confirmation', function(confirmationNumber, receipt){
  
          setmensaje("");
          setmensajeok("");  
  
  
  
          if (receipt.status) {
  
              if(lang == 1) {
              setmensajeok("Transaction confirmed");
              }  
          
              if(lang == 2) {
                setmensajeok("Transaccion confirmada");
              }
          
          
        } else {
  
          
          if(lang == 1) {
            setmensaje("Transaction Failed");
            }
    
            if(lang == 2) {
            setmensaje("Transaccion Fallida");
            }
  
  
  
        }
        
  
        })
  
        .once('error', function(error, receipt){
  
  
          setmensaje("");
          setmensajeok("");  
  
  
          if(lang == 1) {
            setmensaje("Transaction Failed");
            }
    
            if(lang == 2) {
            setmensaje("Transaccion Fallida");
            }
    
        });  
      }catch(e){
        
      }
        

  }
  
  }
  
  
  const switchNetwork = async () => {

    setmensaje("");
  
    await theprovider.request({
      method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(blockchainpolygon) }],
      });
  
  }





return (

    
<div className="BuilderMain1">    

<header className="App-header">


<nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
  <div class="container-fluid">
  <a href={'https://cryptopatron.me/?lang=' + lang} class="navbar-brand"><img src={logotrans} class="img-fluid" width="60" height="auto"></img></a>
    <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">





    <li class="nav-item">

    {lang == 1 &&    

   <a class="nav-link" onClick={HandleSetPolygonTab}><div class="pointer"><div class="pointer"><img src={matic} class="img-fluid" width="20" height="20"></img> Build Polygon Pools</div></div></a>}


    

    {lang == 2 &&    
    
    <a class="nav-link" onClick={HandleSetPolygonTab}><div class="pointer"><div class="pointer"><img src={matic} class="img-fluid" width="20" height="20"></img> Construir Polygon Pools</div></div></a>}
    
    </li>



    <li class="nav-item">

    {(lang == 1 && isconnected > 0) &&
    
    <a class="nav-link" onClick={HandleSetPoolsTab}><div class="pointer"><div class="pointer"><img src={dividends} class="img-fluid" width="20" height="20"></img> My Pools</div></div></a>}
    


    {(lang == 2 && isconnected > 0) &&
    
    
    <a class="nav-link" onClick={HandleSetPoolsTab}><div class="pointer"><div class="pointer"><img src={dividends} class="img-fluid" width="20" height="20"></img> Mis Pools</div></div></a>}
    
    
    

</li>












        



<li class="nav-item">
{lang == 1 &&
<a class="nav-link" onClick={HandleSetSpanish}><div class="pointer">Español</div></a>}

{lang == 2 &&
<a class="nav-link" onClick={HandleSetEnglish}><div class="pointer">English</div></a>}
</li>


      </ul>
    </div>
  </div>
</nav>

<p></p>

<br></br>
<br></br>
<br></br>
<br></br>






{polygontab == 1 &&

<div class="card">
<div class="card-header">

<div class="row">

<div class="col">













{lang == 1 &&
  <p class="text text-start text-dark"><b>Build Polygon Pools</b></p>}
  
  {lang == 2 &&
  <p class="text text-start text-dark"><b>Construir Polygon Pools</b></p>}
  
  


{lang == 1 &&
<p class="text text-start"><a href='https://docs.cryptopatron.me/protocol/crypto-patron/disclaimer' target="_blank"><small>Disclaimer</small></a></p>}

{lang == 2 &&
<p class="text text-start"><a href='https://docs.cryptopatron.me/protocol/crypto-patron/disclaimer' target="_blank"><small>Descargo de responsabilidad</small></a></p>}

</div>

<div class="col">


<p class="text text-end">






{isconnected > 0  &&

  <b class="text text-dark"><div class="pointer"><img src={wallet} class="img-fluid" width="30" height="30" onClick={disconnect}></img></div> {acc.substr(0,6)}...{acc.substr(-4)}</b>
  

}








</p>




</div>
</div>








</div>


<div class="card-body">
















<p class="text text-center">



  {mensaje.length > 0 &&
  <div class="alert alert-danger" role="alert">
  {mensaje}
  </div>}







{mensajeok.length > 0 &&
  <div class="alert alert-success" role="alert">
{mensajeok}
</div>}







</p>

<p></p>


{(isconnected > 0 && Number(blockchainpolygon) === Number(chainid)) &&

<div class="text text-center">
    
<p>
  
  
      {lang == 1 &&
<button type="button" class="btn" onClick={CreatePoolPolygonDAI}>DAI</button>}

    {lang == 2 &&
    <button type="button" class="btn" onClick={CreatePoolPolygonDAI}>DAI</button>}

</p>

<p>

    {lang == 1 &&
    <button type="button" class="btn" onClick={CreatePoolPolygonUSDT}>USDT</button>}

    {lang == 2 &&
    <button type="button" class="btn" onClick={CreatePoolPolygonUSDT}>USDT</button>}

</p>

<p>


    {lang == 1 &&
    <button type="button" class="btn" onClick={CreatePoolPolygonUSDC}>USDC</button>}

    {lang == 2 &&
    <button type="button" class="btn" onClick={CreatePoolPolygonUSDC}>USDC</button>}


</p>

</div>}



{isconnected == 0 &&

<div class="text text-center">


{lang == 1 &&
    <button type="button" class="btn" onClick={connectMetamask}>Connect Wallet</button>}

    {lang == 2 &&
    <button type="button" class="btn" onClick={connectMetamask}>Conectar Wallet</button>}


</div>}



{(isconnected > 0 && Number(blockchainpolygon) !== Number(chainid)) &&

<div class="text text-center">


{lang == 1 &&
    <button type="button" class="btn" onClick={switchNetwork}>Switch Chain</button>}

    {lang == 2 &&
    <button type="button" class="btn" onClick={switchNetwork}>Cambiar Red</button>}


</div>}









</div>
</div>}









{poolstab == 1 &&

<div class="card">
<div class="card-header">




<div class="row">
<div class="col">

{lang == 1 &&  
<p class="text text-start text-dark"><b>My Pools</b></p>}


{lang == 2 &&  
<p class="text text-start text-dark"><b>Mis Pools</b></p>}



</div>

<div class="col">



<p class="text text-end">






{isconnected > 0  &&

  
    <b class="text text-dark"><div class="pointer"><img src={wallet} class="img-fluid" width="30" height="30" onClick={disconnect}></img></div> {acc.substr(0,6)}...{acc.substr(-4)}</b>

  }

</p>



</div>
</div>



</div>



<div class="card-body">



<div class="pointer"><img src={update} class="img-fluid" width="25" height="25" onClick={Refresh}></img></div>
  





<p></p>

<div class="table-responsive table-borderless">
<table class="table">
<tbody>


  <tr>
    
    {lang == 1 &&
    <td><div class="text text-start text-dark"><b>Chain</b></div></td>}

    {lang == 2 &&
    <td><div class="text text-start text-dark"><b>Cadena</b></div></td>}


    <td><div class="text text-start text-dark"><b>Token</b></div></td>  


    

    {lang == 1 &&  
    <td><div class="text text-end text-dark"><b>Pool Link</b></div></td>}
    
    {lang == 2 &&  
    <td><div class="text text-end text-dark"><b>Link del Pool</b></div></td>}
  










</tr>


<p></p>




{poolsdatapolygonV4}



</tbody>

</table>
</div>

</div>
</div>}




  

<div class="container-sm pt-3 text-center" ></div>




</header>
</div>
     

);

}


export default BuilderMainV6;